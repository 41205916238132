function Loyalty() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <rect width="52" height="52" fill="#FFF8E1" rx="26"></rect>
      <path
        fill="#FF6F00"
        d="M26 15a11 11 0 100 22 11 11 0 000-22zm6.248 10.494l-2.343 2.398.55 3.41a1.1 1.1 0 01-1.026 1.295 1.1 1.1 0 01-.591-.14L26 30.862l-2.86 1.573c-.16.094-.342.144-.528.143a1.1 1.1 0 01-1.089-1.298l.561-3.41-2.354-2.376a1.1 1.1 0 01.627-1.859l3.201-.495 1.441-3.058A1.1 1.1 0 0126 19.4a1.1 1.1 0 011.001.627l1.43 3.113 3.201.495a1.1 1.1 0 01.88.737 1.1 1.1 0 01-.264 1.122z"
      ></path>
    </svg>
  );
}

export default Loyalty;
