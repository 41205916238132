import { useRef } from "react";
import { Modal, Button, Table } from "antd";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";
import { getProduckSkuItems } from "../../../api/orders";

export default function ProductSKUModal({ isOpen, closeModal, ids, orders }) {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { data: productSkuItems, isLoading } = useQuery({
    queryFn: () => getProduckSkuItems({ ids }),
    queryKey: ["PRODUCT_SKU_ITEMS", ids],
  });

  const productSkuItemsColumn = [
    {
      key: "key",
      title: "S.N.",
      dataIndex: "key",
      render: (_, __, index) => index + 1,
    },
    {
      key: "name",
      title: "SKU Name",
      dataIndex: "name",
    },
    {
      key: "quantity",
      title: "Quantity",
      dataIndex: "quantity",
    },
  ];

  const ordersColumn = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "orderId",
      render: (_, { id }) => {
        return <div>{id}</div>;
      },
    },
    {
      title: "Customer",
      dataIndex: "phone",
      key: "phone",
      render: (_, { phone, customer_name }) => {
        return <>{customer_name ? `${customer_name} (${phone})` : phone}</>;
      },
    },
    {
      title: "Total Paid Amount",
      dataIndex: "total_paid_amount",
      key: "total_paid_amount",
      render: (_, { total_paid_amount }) => {
        return <>Rs. {total_paid_amount}</>;
      },
    },
  ];

  return (
    <Modal
      footer={
        <Button
          type="primary"
          onClick={() => {
            printRef.current.style.paddingInline = "50px";
            printRef.current.style.paddingBlock = "30px";
            handlePrint();
            printRef.current.style.paddingInline = "0";
            printRef.current.style.paddingBlock = "0";
          }}
        >
          Download
        </Button>
      }
      title="Product SKU"
      visible={isOpen}
      width="50vw"
      onCancel={closeModal}
    >
      <div ref={printRef} className="flex flex-col gap-8">
        <div className="flex flex-col">
          <p className="text-lg">Product SKU Details</p>
          <Table
            columns={productSkuItemsColumn}
            dataSource={productSkuItems
              ?.map((data, index) => ({
                key: index + 1,
                ...data,
                ...data?.product_sku,
              }))
              ?.sort((a, b) =>
                a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
              )}
            loading={isLoading}
            pagination={false}
            size="small"
          />
        </div>

        <div className="flex flex-col">
          <p className="text-lg">Order Details</p>
          <Table
            columns={ordersColumn}
            dataSource={
              orders &&
              orders
                ?.map((data, index) => ({
                  key: index + 1,
                  ...data,
                }))
                ?.sort((a, b) =>
                  a.customer_name?.toUpperCase() >
                  b.customer_name?.toUpperCase()
                    ? 1
                    : -1
                )
            }
            loading={isLoading}
            pagination={false}
            size="small"
          />
        </div>
        <p className="text-right">
          Total Amount: Rs.{" "}
          {orders
            ?.map((data) => data.total_paid_amount)
            ?.reduce((current, next) => Number(current) + Number(next), 0)}
        </p>
      </div>
    </Modal>
  );
}
