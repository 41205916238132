import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <rect width="52" height="52" fill="#EBFEFF" rx="26"></rect>
      <path
        fill="#00A0B0"
        fillRule="evenodd"
        d="M26 17.5c-4.687 0-8.5 3.813-8.5 8.5 0 4.687 3.813 8.5 8.5 8.5 4.687 0 8.5-3.813 8.5-8.5 0-4.687-3.813-8.5-8.5-8.5zM26 36c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#00A0B0"
        d="M26 17.25c-4.825 0-8.75 3.925-8.75 8.75h.5c0-4.549 3.701-8.25 8.25-8.25v-.5zM17.25 26c0 4.825 3.925 8.75 8.75 8.75v-.5c-4.549 0-8.25-3.701-8.25-8.25h-.5zM26 34.75c4.825 0 8.75-3.925 8.75-8.75h-.5c0 4.549-3.701 8.25-8.25 8.25v.5zM34.75 26c0-4.825-3.925-8.75-8.75-8.75v.5c4.549 0 8.25 3.701 8.25 8.25h.5zM26 35.75c-5.376 0-9.75-4.374-9.75-9.75h-.5c0 5.652 4.598 10.25 10.25 10.25v-.5zM16.25 26c0-5.376 4.374-9.75 9.75-9.75v-.5c-5.652 0-10.25 4.598-10.25 10.25h.5zM26 16.25c5.376 0 9.75 4.374 9.75 9.75h.5c0-5.652-4.598-10.25-10.25-10.25v.5zM35.75 26c0 5.376-4.374 9.75-9.75 9.75v.5c5.652 0 10.25-4.598 10.25-10.25h-.5z"
      ></path>
      <path
        fill="#00A0B0"
        fillRule="evenodd"
        d="M29.431 29.692a.756.756 0 01-.384-.105l-3.77-2.248a.755.755 0 01-.366-.645v-4.848a.75.75 0 011.5 0v4.421l3.405 2.03a.752.752 0 01-.385 1.395z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#00A0B0"
        strokeWidth="0.5"
        d="M29.431 29.692a.756.756 0 01-.384-.105l-3.77-2.248a.755.755 0 01-.366-.645v-4.848a.75.75 0 011.5 0v4.421l3.405 2.03a.752.752 0 01-.385 1.395"
      ></path>
    </svg>
  );
}

export default Icon;
