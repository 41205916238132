const LoginIcon = () => {
  return (
    <svg
      width="156"
      height="156"
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="78" cy="78" r="78" fill="#F3F7FE" />
      <mask
        id="mask0_1_16968"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="156"
        height="156"
      >
        <circle cx="78" cy="78" r="78" fill="#F3F7FE" />
      </mask>
      <g mask="url(#mask0_1_16968)">
        <path
          d="M88.3237 74.6264C88.3237 74.6264 80.9738 89.3875 73.5073 99.5763C70.5363 103.632 67.5477 106.962 64.9959 108.352C62.4986 109.711 60.3844 111.82 58.6349 114.163C54.1055 120.223 52.0136 127.809 52.0136 127.809L32.9211 98.8133C32.9211 98.8133 33.5729 70.4703 35.8652 65.7293C39.2062 58.8196 40.8199 44.8601 42.1035 42.0811C43.0562 40.0196 45.7753 29.37 46.0356 28.587C46.1492 28.2503 47.0535 27.7331 48.2289 27.7339C49.1716 27.7339 50.773 28.6487 51.176 30.5291C53.2181 40.0312 50.7607 46.2997 50.7922 46.5779C51.5123 53.8813 49.3474 59.3028 50.8689 60.7386C50.925 60.791 51.0301 60.7663 51.176 60.6707C53.8084 58.9753 70.1441 35.0667 72.1562 34.5503C74.2765 34.0062 88.3237 74.6264 88.3237 74.6264Z"
          fill="#FFB27D"
        />
        <path
          d="M57.7575 122.071C57.7575 122.071 57.4972 134.955 56.7525 152.31H8.59851C19.6026 128.745 29.1726 108.911 29.1726 108.911C29.1726 108.911 56.5936 109.752 57.7575 122.071Z"
          fill="#00B0C2"
        />
        <path
          d="M105.02 24.6698L99.109 33.3705L93.3076 41.9093C92.9537 42.3378 92.5445 42.4865 92.1015 42.4441C90.709 42.3116 88.987 40.2955 87.6443 39.1796L87.5085 39.0671C86.6576 38.3567 86.1215 37.3372 86.0172 36.2312C85.9129 35.1252 86.2489 34.0227 86.9519 33.1647L95.8195 22.4041L98.4496 19.2129C99.1575 18.359 100.173 17.8211 101.275 17.7165C102.376 17.612 103.475 17.9494 104.329 18.6549L104.466 18.7674C105.316 19.4784 105.852 20.4981 105.955 21.6039C106.059 22.7097 105.723 23.8119 105.02 24.6698Z"
          fill="#FFB27D"
        />
        <path
          d="M99.1036 33.3705L93.3022 41.9093C92.9483 42.3378 92.5391 42.4865 92.0962 42.4441L95.8141 22.4072C95.8225 22.5166 96.4544 31.5078 99.1036 33.3705Z"
          fill="#EFA26F"
        />
        <path
          d="M73.5073 99.5763C70.5364 103.632 67.5478 106.962 64.996 108.352C62.4987 109.711 60.3845 111.82 58.6349 114.163C56.6228 113.221 54.3289 112.117 52.0873 111.023C49.3574 109.691 49.2522 105.799 51.9268 104.357C51.9529 104.344 51.9783 104.329 52.0036 104.314C54.5093 102.875 57.0765 97.793 57.0765 97.793L73.5073 99.5763Z"
          fill="#EFA26F"
        />
        <path
          d="M45.9028 29.0279L44.356 35.2979C44.356 35.2979 45.7086 35.8527 46.5769 35.422C47.4451 34.9912 48.1537 29.3654 45.9028 29.0279Z"
          fill="#FFC197"
        />
        <path
          d="M90.3903 17.1968H57.1256C53.8401 17.1968 51.1768 19.8705 51.1768 23.1686V96.5677C51.1768 99.8658 53.8401 102.539 57.1256 102.539H90.3903C93.6757 102.539 96.3391 99.8658 96.3391 96.5677V23.1686C96.3391 19.8705 93.6757 17.1968 90.3903 17.1968Z"
          fill="#374253"
        />
        <path
          d="M94.6722 23.7335V95.431C94.6722 97.8632 92.7038 99.853 90.2964 99.853H58.359C55.9546 99.853 53.9832 97.8632 53.9832 95.431V23.7335C53.9832 21.3013 55.9507 19.3115 58.359 19.3115H90.2994C92.7038 19.3115 94.6722 21.3044 94.6722 23.7335Z"
          fill="white"
        />
        <path
          d="M80.4334 17.697H67.0832C65.9915 17.697 65.1064 18.5854 65.1064 19.6814V19.9943C65.1064 21.0903 65.9915 21.9787 67.0832 21.9787H80.4334C81.5251 21.9787 82.4102 21.0903 82.4102 19.9943V19.6814C82.4102 18.5854 81.5251 17.697 80.4334 17.697Z"
          fill="#374253"
        />
        <path
          d="M102.073 57.525L101.192 57.861L95.2628 60.1228C95.1253 60.1757 94.9848 60.2205 94.8421 60.2569C93.8796 60.5047 92.8598 60.3886 91.977 59.9308C91.0941 59.473 90.4097 58.7053 90.054 57.7739L89.9673 57.5427C89.5901 56.5375 89.6244 55.4233 90.0629 54.4434C90.5013 53.4636 91.3081 52.6977 92.3072 52.3131L97.8346 50.2053L98.2361 50.0512L99.1181 49.7144C100.12 49.3352 101.232 49.3701 102.208 49.8115C103.185 50.2529 103.948 51.0649 104.329 52.0695L104.417 52.3007C104.792 53.3058 104.756 54.419 104.317 55.3977C103.878 56.3763 103.071 57.141 102.073 57.525Z"
          fill="#FFB27D"
        />
        <path
          d="M97.2581 57.6637C96.2693 58.0405 94.4775 58.7079 93.5194 58.8466C93.2484 58.8852 92.7517 58.7372 92.2827 58.2255C92.0032 57.9172 91.7361 57.4811 91.5372 56.8753C91.4407 56.5958 91.3728 56.3071 91.3346 56.0137C91.2971 55.6722 91.3082 55.3271 91.3676 54.9888C91.3978 54.8308 91.4647 54.6823 91.5629 54.5552C91.6611 54.4281 91.7877 54.3261 91.9326 54.2574C91.9326 54.2574 91.9326 54.2574 91.9372 54.2574C92.9559 53.7696 94.3293 53.1739 95.5331 52.8425C96.3653 52.6113 97.2074 52.9805 97.4707 53.7118L97.5951 54.0532L98.2315 55.8026C98.4948 56.527 98.0665 57.3577 97.2581 57.6637Z"
          fill="#FFD3B6"
        />
        <path
          d="M97.2581 57.6637C96.2693 58.0405 94.4775 58.7079 93.5195 58.8466C93.2485 58.8852 92.7518 58.7372 92.2827 58.2255C92.2336 58.1084 92.1875 57.9858 92.1445 57.854C92.0472 57.5747 91.9793 57.286 91.9418 56.9925C91.9046 56.651 91.9154 56.3059 91.9741 55.9675C92.0047 55.8097 92.0718 55.6613 92.1701 55.5344C92.2684 55.4075 92.3951 55.3056 92.5399 55.2369C92.5399 55.2369 92.5399 55.2369 92.5445 55.2369C93.5632 54.7483 94.9359 54.1534 96.1404 53.8212C96.3852 53.7473 96.6435 53.7297 96.896 53.7697C97.1485 53.8098 97.3888 53.9064 97.599 54.0524L98.2354 55.8018C98.4949 56.527 98.0665 57.3577 97.2581 57.6637Z"
          fill="#FFC197"
        />
        <path
          d="M99.383 65.895L94.8099 67.1443C94.1401 67.3252 93.4262 67.2328 92.8241 66.8871C92.222 66.5414 91.7806 65.9706 91.5964 65.2993L91.4551 64.7799C91.2752 64.1068 91.368 63.3896 91.7132 62.785C92.0585 62.1804 92.6281 61.7375 93.2976 61.5532L97.8699 60.304C98.5403 60.1232 99.2548 60.2163 99.857 60.5629C100.459 60.9095 100.9 61.4814 101.083 62.1535L101.224 62.673C101.403 63.3452 101.31 64.0612 100.965 64.6649C100.62 65.2685 100.051 65.7107 99.383 65.895Z"
          fill="#FFB27D"
        />
        <path
          d="M96.9103 65.67C96.0705 65.895 94.5527 66.2919 93.7567 66.3358C93.4672 66.352 92.8876 66.1046 92.5099 65.3425C92.3119 64.9283 92.1976 64.4788 92.1737 64.02C92.1681 63.7546 92.2025 63.4898 92.2758 63.2347C92.3147 63.1127 92.3818 63.0017 92.4716 62.9106C92.5614 62.8196 92.6713 62.7511 92.7924 62.7107H92.7971C93.6684 62.4024 94.8383 62.0333 95.8509 61.8553C96.5495 61.7327 97.2128 62.0703 97.3763 62.649L97.4945 63.0621L97.8469 64.3036C98.0096 64.8755 97.5966 65.4866 96.9103 65.67Z"
          fill="#FFD3B6"
        />
        <path
          d="M96.9104 65.67C96.0705 65.895 94.5528 66.2919 93.7567 66.3358C93.4673 66.352 92.8877 66.1046 92.51 65.3425C92.456 65.1354 92.4248 64.9229 92.4171 64.709C92.4115 64.4435 92.4459 64.1788 92.5192 63.9237C92.5583 63.8015 92.6255 63.6904 92.7154 63.5992C92.8053 63.508 92.9153 63.4394 93.0366 63.3989C93.9079 63.0906 95.0787 62.7207 96.0905 62.5442C96.6693 62.4425 97.2244 62.6567 97.4908 63.0613L97.8431 64.3028C98.0097 64.8754 97.5967 65.4866 96.9104 65.67Z"
          fill="#FFC197"
        />
        <path
          d="M24.4851 118.687C24.4851 118.687 33.8954 127.735 40.2396 131.228L45.043 127.808V132.854C45.043 132.854 54.4856 134.531 57.4688 132.854V119.095L30.9383 105.743L24.4851 118.687Z"
          fill="#00A0B0"
        />
        <path
          d="M59.4733 131.759C55.8183 132.512 47.941 129.629 47.941 129.629L49.2093 117.31L40.3041 127.543C35.7747 125.848 28.689 121.435 24.4797 118.687C22.3839 117.318 21.0005 116.363 21.0005 116.363C26.9409 106.767 32.9158 97.7906 32.9158 97.7906C34.67 101.313 38.8209 104.371 43.3211 106.818C50.7991 110.884 59.2399 113.259 59.2399 113.259C59.2422 120.583 59.4733 131.759 59.4733 131.759Z"
          fill="#DCEAFF"
        />
        <path
          d="M101.929 48.731L101.83 48.7649L95.8363 50.3062C93.2009 50.9867 90.4748 49.5549 89.7793 47.1258L89.4231 45.8881C88.7544 43.5553 90.1908 41.1162 92.6404 40.3255L94.6195 39.8154L98.6345 38.7804C101.27 38.1007 103.995 39.5325 104.691 41.9616L105.048 43.2062C105.706 45.5028 104.317 47.9064 101.929 48.731Z"
          fill="#FFB27D"
        />
        <path
          d="M99.1858 47.0511L96.2686 48.3689H96.2502C96.1343 48.4013 96.0166 48.427 95.8978 48.4459C95.8495 48.4536 95.7996 48.459 95.7504 48.4644C95.73 48.4664 95.7094 48.4664 95.689 48.4644C95.613 48.4618 95.5388 48.4404 95.4731 48.402C95.4074 48.3637 95.3521 48.3096 95.3123 48.2445C95.2724 48.1795 95.2493 48.1056 95.2448 48.0294C95.2404 47.9532 95.2548 47.8771 95.2867 47.8078C95.5722 47.1872 95.6307 46.4854 95.4518 45.8257L95.3819 45.5706C95.3825 45.5681 95.3825 45.5655 95.3819 45.5629L95.1562 44.7322C94.9842 44.094 94.6146 43.5273 94.1007 43.1138C94.0408 43.0653 93.9794 43.019 93.9164 42.9759C93.3406 42.5751 93.4873 41.6658 94.1613 41.4808L94.1797 41.4754L97.3756 40.9899C98.1656 40.7749 98.9264 41.3852 99.1413 42.1782L99.3977 43.1238L99.9443 45.1398C100.159 45.9382 99.972 46.8361 99.1858 47.0511Z"
          fill="#FFD3B6"
        />
        <path
          d="M99.1856 47.0511L96.2685 48.3689H96.25C96.1817 48.382 95.9683 48.4182 95.6888 48.4614C95.6129 48.4588 95.5387 48.4373 95.4729 48.399C95.4072 48.3606 95.352 48.3065 95.3121 48.2415C95.2723 48.1764 95.2491 48.1025 95.2447 48.0263C95.2402 47.9501 95.2546 47.874 95.2866 47.8048C95.5721 47.1841 95.6305 46.4823 95.4516 45.8226L95.3818 45.5676C95.3824 45.565 95.3824 45.5624 95.3818 45.5599L95.1561 44.7322C94.9841 44.094 94.6144 43.5273 94.1005 43.1138C94.2102 42.9529 94.3736 42.8367 94.5611 42.7863L94.5796 42.7809L97.7754 42.2962C98.441 42.1143 99.0843 42.5189 99.4006 43.1238L99.9472 45.1398C100.159 45.9382 99.9717 46.8361 99.1856 47.0511Z"
          fill="#FFC197"
        />
        <path
          d="M51.176 80.0279C51.176 80.0279 49.871 68.5691 48.6626 66.335C47.4543 64.1009 46.4363 59.625 48.2911 56.3004C50.1458 52.9758 50.7884 46.5817 50.7884 46.5817C51.5085 53.8852 49.3436 59.3067 50.8651 60.7424C50.9212 60.7948 51.0264 60.7702 51.1722 60.6746L51.176 80.0279Z"
          fill="#EFA26F"
        />
        <path
          d="M59.4733 131.759C55.8183 132.512 47.9411 129.629 47.9411 129.629L49.2093 117.31L40.3041 127.543C35.7747 125.848 28.689 121.435 24.4797 118.687C35.2673 117.506 43.3211 106.819 43.3211 106.819C50.7992 110.885 59.2399 113.26 59.2399 113.26C59.2422 120.583 59.4733 131.759 59.4733 131.759Z"
          fill="#CBE1FD"
        />
        <path
          d="M34.3929 120.569C34.3957 120.275 34.4853 119.988 34.6503 119.745C34.8154 119.502 35.0486 119.313 35.3203 119.203C35.5921 119.093 35.8903 119.067 36.1771 119.127C36.464 119.187 36.7266 119.331 36.9319 119.541C37.1372 119.751 37.2758 120.017 37.3303 120.306C37.3848 120.595 37.3527 120.894 37.238 121.165C37.1234 121.435 36.9313 121.666 36.6862 121.827C36.441 121.988 36.1538 122.073 35.8608 122.07C35.6662 122.068 35.4739 122.028 35.2948 121.952C35.1157 121.875 34.9534 121.764 34.8171 121.625C34.6808 121.485 34.5732 121.32 34.5004 121.139C34.4276 120.958 34.3911 120.764 34.3929 120.569Z"
          fill="#F99746"
        />
        <circle cx="62.5815" cy="87.2126" r="3.68504" fill="#E6EAF0" />
        <circle cx="74.865" cy="87.2126" r="3.68504" fill="#E6EAF0" />
        <circle cx="87.1484" cy="87.2126" r="3.68504" fill="#E6EAF0" />
        <circle cx="62.5815" cy="74.9291" r="3.68504" fill="#E6EAF0" />
        <circle cx="74.865" cy="74.9291" r="3.68504" fill="#E6EAF0" />
        <circle cx="87.1484" cy="74.9291" r="3.68504" fill="#E6EAF0" />
        <circle cx="62.5815" cy="62.6457" r="3.68504" fill="#E6EAF0" />
        <circle cx="74.865" cy="62.6457" r="3.68504" fill="#E6EAF0" />
        <circle cx="87.1484" cy="62.6457" r="3.68504" fill="#E6EAF0" />
        <circle cx="62.5815" cy="50.3622" r="3.68504" fill="#E6EAF0" />
        <circle cx="74.865" cy="50.3622" r="3.68504" fill="#E6EAF0" />
        <circle cx="87.1484" cy="50.3622" r="3.68504" fill="#E6EAF0" />
        <path
          d="M142.622 152.315H107.304C105.61 151.175 96.8151 145.153 93.1785 140.663C92.4108 139.711 89.5865 131.511 87.4209 124.854C86.0674 120.692 84.9643 117.136 84.7823 116.32C84.7178 116.036 84.6748 115.472 84.6487 114.71V114.702C84.5566 111.979 84.6825 106.729 84.8123 102.558V102.539V102.484C84.8184 102.301 84.8238 102.12 84.8299 101.945V101.938C84.8299 101.921 84.8299 101.902 84.8299 101.884C84.8537 101.16 84.8767 100.477 84.8982 99.856C84.9527 98.304 84.9988 97.1364 85.018 96.6794L85.0257 96.4667C85.0817 96.4351 86.7806 95.525 89.0514 97.1672C89.5996 97.5641 90.1062 98.5228 90.5684 99.7736C90.864 100.572 91.1411 101.489 91.399 102.455C92.3909 106.172 93.1025 110.616 93.4825 111.806C94.0967 113.732 101.313 122.184 101.767 122.71C101.774 122.719 101.782 122.727 101.789 122.736V109.379L96.3383 94.7366L94.1005 88.7255L92.9759 85.7046C91.7853 83.6876 90.7257 81.5956 89.8038 79.4415C89.1704 77.9449 88.5901 76.3381 88.2308 74.8107C88.1448 74.4469 88.0719 74.087 88.0135 73.7318C87.8009 72.4409 87.7832 71.2433 88.0818 70.2685C88.6092 68.5322 89.7976 68.2054 90.7104 68.2409C90.8048 68.2409 90.8962 68.2525 90.9845 68.2625C91.0731 68.2731 91.1608 68.2901 91.247 68.3133C91.4061 68.3557 91.5587 68.4195 91.7007 68.5029C91.937 68.6419 92.1395 68.8319 92.2934 69.0593C92.2934 69.0593 93.0611 70.0696 94.0982 71.4275C94.7784 72.3215 95.573 73.3657 96.336 74.3722C97.6949 76.1655 98.9569 77.8401 99.3039 78.3287C101.179 80.979 106.229 89.6156 106.229 89.6156L112.14 89.8646C113.077 89.9038 113.968 90.2783 114.653 90.9204C116.755 92.8909 121.287 97.1549 121.843 97.7892C121.843 97.9972 125.364 99.6973 128.085 101.211C130.031 102.036 133.727 104.962 133.727 104.962C134.495 105.641 135.077 106.316 135.372 106.965C136.64 109.734 140.077 133.816 141.721 145.721C142.253 149.548 142.595 152.117 142.622 152.315Z"
          fill="#FFB27D"
        />
        <path
          d="M106.232 89.6187C106.232 89.6187 111.708 98.5922 112.432 98.996C113.157 99.3998 115.976 99.9662 116.298 98.996C116.621 98.0257 106.232 89.6187 106.232 89.6187Z"
          fill="#EFA26F"
        />
        <path
          d="M133.757 104.989L133.729 104.965C133.729 104.965 130.033 102.036 128.087 101.214C125.589 99.8268 122.417 98.2778 121.914 97.8724C122.493 98.5382 126.571 103.171 128.141 103.518C129.519 103.823 132.649 104.683 133.757 104.989Z"
          fill="#EFA26F"
        />
        <path
          d="M101.79 122.732V109.379C101.79 109.379 104.949 114.685 105.191 117.596C105.433 120.506 103.879 125.217 101.79 122.732Z"
          fill="#EFA26F"
        />
        <path
          d="M90.3243 73.731C89.8637 72.9357 89.0346 71.4961 88.7498 70.6869C88.673 70.4557 88.6799 70.0026 88.9593 69.5163C89.1658 69.1681 89.4495 68.8725 89.7884 68.6524C89.969 68.524 90.1644 68.4182 90.3704 68.3372C90.6092 68.2498 90.8609 68.2032 91.115 68.1993C91.2431 68.2049 91.368 68.2416 91.4788 68.3063C91.5897 68.371 91.6832 68.4617 91.7514 68.5707C92.2996 69.373 92.9936 70.465 93.4657 71.4537C93.792 72.1365 93.6891 72.9249 93.2131 73.2709L92.9905 73.4343L91.8528 74.2681C91.3822 74.6172 90.7005 74.3814 90.3243 73.731Z"
          fill="#FFD3B6"
        />
        <path
          d="M90.3243 73.731C89.8637 72.9357 89.0346 71.4962 88.7498 70.687C88.673 70.4558 88.6799 70.0026 88.9593 69.5163C89.2077 69.3115 89.4847 69.1445 89.7815 69.0208C90.0203 68.9335 90.272 68.8868 90.5262 68.8829C90.6543 68.8884 90.7792 68.9251 90.89 68.9898C91.0009 69.0545 91.0944 69.1453 91.1626 69.2543C91.7115 70.0566 92.4055 71.1486 92.8768 72.1366C93.0864 72.5743 93.1194 73.0544 92.9904 73.4336L91.8527 74.2674C91.3821 74.6173 90.7004 74.3815 90.3243 73.731Z"
          fill="#FFC197"
        />
        <path
          d="M124.878 146.832L114.328 152.315H107.304C105.611 151.175 96.8152 145.153 93.1786 140.663C92.4109 139.711 89.5866 131.511 87.421 124.854C87.7088 125.738 93.759 138.673 95.4817 140.143C97.2044 141.614 106.249 150.174 108.747 150.085C111.167 150.002 124.049 147.025 124.847 146.841L124.878 146.832Z"
          fill="#EFA26F"
        />
        <path
          d="M146.106 152.315H108.07C109.978 151.564 117.276 148.765 124.842 146.843L124.878 146.835C131.376 145.185 138.065 144.185 141.697 145.685L141.723 145.724L146.106 152.315Z"
          fill="#D2E3FC"
        />
        <path
          d="M84.8299 101.881C85.4702 101.645 86.3706 101.49 86.8082 102.224C87.4899 103.366 84.9581 113.443 84.6418 114.709L84.8299 101.881Z"
          fill="#EFA26F"
        />
        <path
          d="M85.0249 96.4628L84.8077 102.558C84.8077 102.558 86.0844 102.767 86.8099 102.223C87.5354 101.679 87.1515 96.3912 85.0249 96.4628Z"
          fill="#FFC197"
        />
        <rect
          x="58.8965"
          y="31.937"
          width="31.937"
          height="2.45669"
          fill="#FFB27D"
        />
        <rect
          x="58.8965"
          y="35.622"
          width="20.8819"
          height="2.45669"
          fill="#E6EAF0"
        />
      </g>
    </svg>
  );
};

export default LoginIcon;
